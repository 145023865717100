<template>
  <v-container
    fluid
  >
    <app-api-call
      class="ma-0 pa-4"
      :call-result="callResult"
    />

    <basic-resp-row
      left="$l.settings.userEmail"
      :right="userData.email"
      :call-result="callResult"
      width="100%"
      rowcss="body-2 ma-0 mb-3 info1--text"
    />

    <basic-resp-row
      left="$l.settings.memberFrom"
      :right="$utils.formatDate(userData.registered)"
      :call-result="callResult"
      width="100%"
      rowcss="body-2 ma-0 mb-3 info1--text"
    />

    <v-row
      class="body-2 ma-0 mb-3 info1--text align-center"
    >
      <span
        class="mr-3"
      >
        {{ $i18n.t('$l.settings.pdfReportPassword') }}
      </span>
      <span
        class="d-inline-flex mt-1"
        style="width: 100%"
      >
        <v-text-field
          ref="pdfPasswordField"
          v-model="userData.pdfReportPassword"
          class="ma-0 pa-0"
          :type="hiddenPassword ? 'password' : 'text'"
          :append-icon="hiddenPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          prepend-icon="mdi-lock"
          :readonly="!editPdfPassword"
          :rules="[v => !!v || $i18n.t('$l.app.required')]"
          required
          maxlength="32"
          counter
          @click:append="() => (hiddenPassword = !hiddenPassword)"
        />
        <v-btn
          class="primary mx-3"
          fab
          text
          x-small
          @click="doEeditPdfPassword()"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          color="info"
          fab
          elevation="0"
          x-small
          :disabled="!editPdfPassword"
          :loading="savingPdfPassword"
          @click="savePdfPassword()"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
      </span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BasicUserData',
  data () {
    return {
      callResult: { finished: false, authorized: true, error: null, info: null },
      userData: {},
      hiddenPassword: true,
      editPdfPassword: false,
      savingPdfPassword: false
    }
  },
  created () {
    this.loadUserData()
  },
  methods: {
    loadUserData () {
      this.callResult.error = null
      this.callResult.finished = false
      this.$xapi.get('endpointsUserProfile/getSingleUser')
        .then(r => {
          this.userData = r.data
          this.userData.email = JSON.parse(this.userData.data).email
          this.userData.registered = JSON.parse(this.userData.data).registrationTS
          this.userData.pdfReportPassword = JSON.parse(this.userData.amloeUserProfile.data).profileData.pdfReportPassword
        })
        .catch(e => {
          this.callResult.error = e.response.localizedMessage
        })
        .finally(() => {
          this.callResult.finished = true
        })
    },
    doEeditPdfPassword () {
      this.editPdfPassword = !this.editPdfPassword
      this.hiddenPassword = !this.editPdfPassword
    },
    savePdfPassword () {
      this.callResult.error = null
      if (this.editPdfPassword && this.$refs.pdfPasswordField.validate()) {
        this.savingPdfPassword = true
        this.$xapi.post('endpointsUserProfile/savePdfPassword', this.userData.pdfReportPassword)
          .catch(e => {
            this.callResult.error = e.response.localizedMessage
          })
          .finally(() => {
            this.hiddenPassword = true
            this.editPdfPassword = false
            this.savingPdfPassword = false
            this.loadUserData()
          })
      }
    }
  }
}
</script>
